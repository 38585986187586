<template>
  <!-- 提现待审核详情 -->
  <div class="container">
    <a-page-header :title="title" @back="() => $router.go(-1)">
      <template slot="extra" v-if="info">
        <a-button
          type="primary"
          :disabled="info.castatus > 0"
          @click="handleSubmit"
        >
          出纳审批
        </a-button>
      </template>
    </a-page-header>
    <div class="main-content" v-if="info">
      <a-steps v-model="stepsCurrent" type="navigation" :status="stepsStatus">
        <a-step title="申请提现" disabled />
        <a-step title="会计审批" disabled />
        <a-step title="出纳打款" disabled />
      </a-steps>

      <div class="content-item" v-if="info">
        <div class="content-item-header">
          <div class="title">提现账号详情</div>
        </div>

        <div class="ul">
          <div class="li">
            <span>账号类型：</span>
            <span>{{ typeText[info.userType] }}</span>
          </div>
          <div class="li">
            <span>系统账号：</span>
            <a-tooltip placement="topLeft" :title="info.systemNum">
              <span>{{ info.systemNum }}</span>
            </a-tooltip>
          </div>
          <div class="li">
            <span>账号使用人：</span>
            <span>{{ info.accoutUser }}</span>
          </div>
        </div>

        <div class="ul ul-2">
          <div class="li">
            <span>手机号：</span>
            <span>{{ info.mobile }}</span>
          </div>
        </div>
      </div>

      <div class="content-item">
        <div class="content-item-header">
          <div class="title">账目详情</div>
        </div>

        <div class="content-sub-title">提现概括</div>

        <div class="ul">
          <div class="li">
            <span>审批状态：</span>
            <span>{{ astatusText[info.astatus] }}</span>
          </div>
          <div class="li">
            <span>提现金额：</span>
            <a-statistic class="a-statistic" prefix="￥" :value="info.price" />
          </div>
          <div class="li">
            <span>审批时间：</span>
            <span v-if="info.accountTime">{{ info.accountTime }}</span>
            <span v-else>-</span>
          </div>
        </div>

        <div class="ul ul-2">
          <div class="li">
            <span>申请时间：</span>
            <span>{{ info.createAt }}</span>
          </div>
          <div class="li">
            <span>提现流水号：</span>
            <a-tooltip placement="topLeft" :title="info.outTradeNo">
              <span>{{ info.outTradeNo }}</span>
            </a-tooltip>
          </div>
        </div>

        <a-divider />

        <div class="content-sub-title">出纳概括</div>

        <div class="ul">
          <div class="li">
            <span>打款状态：</span>
            <span>{{ castatusText[info.castatus] }}</span>
          </div>
          <div class="li">
            <span>打款方式：</span>
            <span v-if="info.remitType">
              {{ cashiers[info.remitType - 1] }}
            </span>
            <span v-else>-</span>
          </div>
          <div class="li">
            <span>出纳打款时间：</span>
            <span v-if="info.cashierTime">{{ info.cashierTime }}</span>
            <span v-else>-</span>
          </div>
        </div>
        <div class="ul ul-2">
          <div class="li">
            <span>商户交易状态：</span>
            <span v-if="info.return_code">{{
              transactionText[info.return_code]
            }}</span>
            <span v-else>-</span>
          </div>
          <div class="li">
            <span>商户交易号：</span>
            <a-tooltip
              placement="topLeft"
              :title="info.partner_trade_no"
              v-if="info.partner_trade_no"
            >
              <span>
                {{ info.partner_trade_no }}
              </span>
            </a-tooltip>
            <span v-else>-</span>
          </div>
          <div class="li">
            <span>实际到账时间：</span>
            <span v-if="info.payment_time">{{ info.payment_time }}</span>
            <span v-else>-</span>
          </div>
        </div>

        <a-divider />

        <div class="content-sub-title">提现业绩详情</div>

        <a-table
          :columns="columns"
          :data-source="info.bill"
          :pagination="false"
          :row-key="(record) => record.id"
          :scroll="{ x: 1020 }"
        >
          <template slot="price" slot-scope="text">
            <a-statistic prefix="￥" :value="text" />
          </template>
          <template slot="outTradeNo" slot-scope="record">
            <span class="order-num" @click="onOrderNumClick(record.orderId)">
              {{ record.outTradeNo }}
            </span>
          </template>
        </a-table>
      </div>
    </div>

    <a-modal v-model="modalVisible" title="出纳打款审批" :width="555">
      <div class="content-item content-item-modal" v-if="info">
        <div class="content-sub-title">提现概括</div>

        <div class="ul">
          <div class="li">
            <span>账号使用人：</span>
            <span>{{ info.accoutUser }}</span>
          </div>
          <div class="li">
            <span>提现金额：</span>
            <a-statistic class="a-statistic" prefix="￥" :value="info.price" />
          </div>
        </div>
      </div>
      <a-divider />
      <div class="content-sub-title">打款方式</div>
      <a-radio-group v-model="cashierValue" :options="cashierOptions" />
      <template slot="footer">
        <a-button @click="modalVisible = false">
          取消
        </a-button>
        <a-button
          type="danger"
          :loading="modalLoading2"
          @click="handleChange(2)"
        >
          拒绝打款
        </a-button>
        <a-button
          type="primary"
          :loading="modalLoading1"
          @click="handleChange(1)"
        >
          确认打款
        </a-button>
      </template>
    </a-modal>

    <div class="embedded-page-wrap" v-if="isOrderItemInfo">
      <div class="embedded-page">
        <div class="embedded-page-header">
          <div class="embedded-page-header-text">订单详情</div>
          <div class="embedded-page-close-btn" @click="onOrderItemInfoClose">
            <a-icon type="close" />
          </div>
        </div>
        <order-item-info></order-item-info>
      </div>
    </div>
  </div>
</template>

<script>
import orderItemInfo from './order-item-info'
export default {
  data() {
    return {
      title: '',
      stepsCurrent: 1,
      stepsStatus: 'process',
      info: null,
      typeText: {
        admin: '平台',
        proxy: '代理企业',
        salesman: '业务员',
        madmin: '中台管理账号',
        servicer: '作业师傅',
        lector: '理论讲师',
        training: '实操讲师',
        apprentice: '学徒',
        visitiservicer: '上门实训师傅',
        member: '注册用户',
        business: '渠道商',
        bterminal: '渠道商终端',
         community:"渠道经理"
      },
      astatusText: {
        0: '会计审批中',
        1: '会计已审批',
        2: '会计已拒绝'
      },
      castatusText: {
        0: '出纳审批中',
        1: '出纳已审批',
        2: '出纳已拒绝'
      },
      transactionText: {
        SUCCESS: '交易成功',
        FAIL: '交易失败'
      },
      ordertypeText: {
        1: '服务业绩',
        2: '绩效奖励'
      },
      columns: [
        {
          title: '序号',
          width: 70,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '业绩类型',
          dataIndex: 'ordertype',
          width: 100,
          customRender: (text) => {
            return this.ordertypeText[text]
          }
        },
        {
          title: '业绩金额',
          dataIndex: 'price',
          scopedSlots: { customRender: 'price' },
          width: 150
        },
        {
          title: '业绩流水号',
          dataIndex: 'outnumber',
          width: 200,
          customRender: (text) => {
            return text ? text : ''
          }
        },
        {
          title: '业绩下发时间',
          dataIndex: 'sendAt',
          width: 200,
          customRender: (text) => {
            return text ? text : ''
          }
        },
        {
          title: '订单号',
          scopedSlots: { customRender: 'outTradeNo' }
        },
        {
          title: '订单创建时间',
          dataIndex: 'createAt',
          width: 200,
          customRender: (text) => {
            return text ? text : ''
          }
        }
      ],
      modalVisible: false,
      modalLoading1: false,
      modalLoading2: false,
      isOrderItemInfo: false,
      cashierOptions: [
        {
          value: 1,
          label: '微信支付（实时打款）'
        },
        {
          value: 2,
          label: '人工打款（系统不记录打款结果）'
        }
      ],
      cashiers: ['微信支付', '人工打款'],
      cashierValue: 1
    }
  },
  components: {
    orderItemInfo
  },
  created() {
    this.title = this.$getPageTitle()
    const id = this.$route.query.id
    this.getWithdrawalApproveCashierInfo(id)
  },
  methods: {
    getWithdrawalApproveCashierInfo(id) {
      // 获取详情
      const data = {
        id: id
      }
      this.$axios.getWithdrawalApproveCashierInfo(data).then((res) => {
        const v = res.data.data
        this.info = v
        if (v.astatus === 1) {
          // 会计同意
          this.stepsCurrent = 2
        }
        if (v.astatus === 2) {
          // 会计拒绝
          this.stepsCurrent = 1
          this.stepsStatus = 'error'
        }
        if (v.castatus === 1) {
          // 出纳同意
          this.stepsCurrent = 2
          this.stepsStatus = 'finish'
        }
        if (v.castatus === 2) {
          // 出纳拒绝
          this.stepsCurrent = 2
          this.stepsStatus = 'error'
        }
      })
    },
    handleSubmit() {
      // 审批
      this.modalVisible = true
    },
    handleChange(e) {
      const data = {
        id: this.info.id,
        status: e
      }
      if (e === 1) {
        data.remit = this.cashierValue
        this.modalLoading1 = true
      } else {
        this.modalLoading2 = true
      }
      this.$axios.withdrawalApproveCashier(data).then(() => {
        this.$message.success('操作成功')
        if (e === 1) {
          this.modalLoading1 = false
        } else {
          this.modalLoading2 = false
        }
        this.modalVisible = false
        this.getWithdrawalApproveCashierInfo(this.info.id)
      })
    },
    onOrderNumClick(id) {
      // 显示订单详情弹窗
      this.$router.replace({
        query: {
          id
        }
      })
      this.isOrderItemInfo = true
    },
    onOrderItemInfoClose() {
      // 关闭订单详情弹窗
      this.$router.replace({
        query: {
          id: this.info.id
        }
      })
      this.isOrderItemInfo = false
    }
  }
}
</script>

<style src="../../assets/css/info-page.css" scoped />
<style scoped>
.content-item:first-child {
  padding: 0;
}

.content-item:first-child .content-item-header {
  padding: 16px 32px;
  border-bottom: 1px solid #e9e9e9;
}

.ant-steps-navigation {
  box-shadow: none;
  background-color: #fff;
  padding: 24px 0 12px 0;
}

.ant-steps .ant-steps-item.ant-steps-item-active::before {
  width: 0;
}

.content-item {
  margin-top: 16px;
}

.order-num {
  color: #1890ff;
  cursor: pointer;
}

.content-item-modal .ul .li {
  margin: 0;
}
</style>
